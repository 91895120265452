import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConfig } from './../app.config';
import { DataService } from './dataService';
import { PromoItem } from './../domain/promo';
import { PriceItem } from './../domain/price';
import { Comment } from './../domain/comment';
import { IpInfoItem } from '../domain/ipinfo';
import { AirlineItem } from '../domain/airline';
import { ApiHelper } from '../helper/api.helper';
import { SignIn } from '../domain/object/signin';
import { SignUp } from '../domain/object/signup';
import { PaymentItem } from './../domain/payment';
import { ProfileItem } from './../domain/profile';
import { AirportItem } from './../domain/airport';
import { InvoiceItem } from './../domain/invoice';
import { QuotationItem } from './../domain/quotation';
import { PromotionItem } from './../domain/promotion';
import { PickupType } from '../domain/enum/pickup.type';
import { UtilityHelper } from '../helper/utility.helper';
import { BaseItem, BaseUrlItem } from './../domain/base';
import { environment } from '../../environments/environment';
import { InvoiceReportItem } from './../domain/invoicereport';
import { ConfigurationItem } from './../domain/configuration';
import { AddressItem, ViaAddress } from './../domain/address';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VehicleItem, TrackVehicleItem } from './../domain/vehicle';
import { CommissionItem, CommissionFilter } from './../domain/commission';
import { Flight, FlightResultItem, FlightParamSearch } from './../domain/flight';
import { DriverFootprintItem, DriverItem, DriverPhotoItem } from './../domain/driver';
import { Booking, BookingInfoItem, BookingOption, UpdateBooking } from './../domain/booking';
import { ElectronicItem, ElectronicBookingItem, ElectronicBookingStripeItem } from './../domain/electronic';

@Injectable()
export class ApiService {

    constructor(private http: HttpClient, private data: DataService) {
    }

    searchAirports(term: string) {
        let params = new HttpParams().set('searchString', term);
        let api = AppConfig.API + ApiHelper.apiSearchAirport;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => data.airports),
            map(data => AirportItem.ToItems(data))
        );
    }
    searchAirlines(term: string) {
        let params = new HttpParams().set('searchstring', term);
        let api = AppConfig.API + ApiHelper.apiSearchAirport;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => data.airlines),
            map(data => AirlineItem.ToItems(data))
        );
    }
    searchAddress(term: string, type: string) {
        let params = new HttpParams().set('prefix', term);
        let api = AppConfig.API + ApiHelper.apiSearchAddress;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => data.addresses),
            map(data => AddressItem.ToItems(data))
        );
    }
    searchAdvanceFlights(item: FlightParamSearch) {
        let params = new HttpParams()
            .set('destination', item.PickUpCode)
            .set('airline', item.Airline && item.Airline.Code)
            .set('origin', (item.Airport && item.Airport.Code) || '')
            .set('departuretime', UtilityHelper.ToLocalTime(item.DateTime));
        let api = AppConfig.API + ApiHelper.apiSearchAdvanceFlight;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => data.AirlineFlightSchedulesResult.data),
            map(data => FlightResultItem.AdvanceToItems(data))
        );
    }
    searchFlights(term: string, landingTime: Date) {
        let params = new HttpParams().set('flightNo', term);
        if (landingTime)
            params = params.set('landingTime', UtilityHelper.ToLocalTime(landingTime));
        let api = AppConfig.API + ApiHelper.apiSearchFlight;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => data.FlightInfoResult?.flights),
            map(data => FlightResultItem.ToItems(data))
        );
    }

    getAddressBook(id: number) {
        let params = new HttpParams().set('custid', !id ? '0' : id.toString());
        let api = AppConfig.API + ApiHelper.apiAddressBook;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => data.addresses),
            map(data => AddressItem.ToAddressBookItems(data))
        );
    }
    deleteAddressBook(id: number) {
        let api = AppConfig.API + ApiHelper.apiAddressBook + '?id=' + id;
        return this.http.delete(api).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }
    addAddressBook(id: number, item: AddressItem) {
        let params: any = {
            custid: id,
            name: item.Name,
            line1: item.Line1,
            line2: item.Line2,
            postcode: item.Postcode,
            category: item.Category,
            latitude: item.Latitude,
            longitude: item.Longitude,
            placename: item.PlaceName,
            fulladdress: item.Fulladdress,
        };
        let api = AppConfig.API + ApiHelper.apiAddressBook;
        return this.http.post(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }
    saveAddressBook(id: number, item: AddressItem) {
        let params: any = {
            custid: id,
            id: item.Id,
            name: item.Name,
            line1: item.Line1,
            line2: item.Line2,
            postcode: item.Postcode,
            category: item.Category,
            latitude: item.Latitude,
            longitude: item.Longitude,
            placename: item.PlaceName,
            fulladdress: item.Fulladdress,
        };
        let api = AppConfig.API + ApiHelper.apiAddressBook;
        return this.http.put(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }

    configuration(sandbox: boolean = false, currencyCode = '') {
        let sourceType = AppConfig.SOURCE_TYPE;

        let params = new HttpParams().set('airports', 'true');
        if (sandbox)
            params = params.set('sandbox', 'true');
        if (sourceType > 0)
            params = params.set('source', sourceType.toString());
        if (currencyCode)
            params = params.set('currencyCode', currencyCode);

        let api = AppConfig.API + ApiHelper.apiConfiguration;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => ConfigurationItem.ToItem(data))
        );
    }

    direction(booking: Booking) {
        let points = new Array();
        points.push({ lat: booking.PickUp.Latitude, lng: booking.PickUp.Longitude });
        if (booking.ViaAddress) {
            booking.ViaAddress.forEach(element => {
                if (element != null) {
                    points.push({ lat: element.Latitude, lng: element.Longitude });
                }
            });
        }
        points.push({ lat: booking.PickDown.Latitude, lng: booking.PickDown.Longitude });

        let params: any = {
            points: points,
        };
        let api = AppConfig.API + ApiHelper.apiDirection;
        return this.http.post(api, params).pipe(
            map((data: any) => QuotationItem.ToItemFromDirection(data.route))
        );
    }
    nearestDriver(item: AddressItem) {
        let sourceType = AppConfig.SOURCE_TYPE;
        let params: any = {
            source: sourceType,
            lat: item.Latitude,
            lng: item.Longitude,
            postcode: item.Postcode,
        };
        let api = AppConfig.API + ApiHelper.apiNearestDriver;
        return this.http.post(api, params).pipe(
            map((data: any) => DriverItem.ToItem(data))
        );
    }
    quotation(booking: Booking, calcRoute: boolean = false, calcDriver: boolean = false, currencyCode = '', paymentmethod: string = '') {
        let sourceType = AppConfig.SOURCE_TYPE;
        let viaaddresses = new Array();
        if (booking.ViaAddress) {
            booking.ViaAddress.forEach(element => {
                if (element != null) {
                    let viaAddress = ViaAddress.ToViaAddress(element);
                    viaaddresses.push(viaAddress);
                }
            });
        }

        let time = booking.Time.Time;
        if (time == null) {
            time = (new Date());
            let minute = booking.Minute == 0 ? 15 : booking.Minute;
            time.setMinutes(time.getMinutes() + minute);
        }
        if (booking.Time.PickUpType == PickupType.Now) {
            time = (new Date());
            let minute = booking.Quotation != null ? booking.Quotation.TravelTime : 0;
            time.setMinutes(time.getMinutes() + minute);
        }
        let rtntype = '';
        if (booking.WaitAndReturn) rtntype = 'w&r';
        else if (booking.HasReturn && booking.ReturnDate != null) rtntype = 'rtn';
        let params = {
            rtntype: rtntype,
            source: sourceType,
            recalcroute: calcRoute,
            viaaddresses: viaaddresses,
            currencyCode: currencyCode,
            paymentmethod: paymentmethod,
            recalcnearestdriver: calcDriver,
            pick: booking.PickUp.Fulladdress,
            picklat: booking.PickUp.Latitude,
            picklng: booking.PickUp.Longitude,
            doff: booking.PickDown.Fulladdress,
            dofflat: booking.PickDown.Latitude,
            custid: booking.Contact.CustomerId,
            bookerid: booking.Contact.BookerId,
            dofflng: booking.PickDown.Longitude,
            pickpostcode: booking.PickUp.Postcode,
            droppostcode: booking.PickDown.Postcode,
            traveltime: booking.Quotation.TravelTime,
            distance: booking.Quotation.RouteDistance,
            bookingdate: UtilityHelper.ToLocalTime(time),
            returntraveltime: booking.Quotation.ReturnJourneyTime,
            returndistance: booking.Quotation.ReturnRouteDistance,
            returndate: UtilityHelper.ToLocalTime(booking.ReturnDate),
            vehtypeid: booking.Vehicle == null ? 1 : booking.Vehicle.Id,
            requestid: ((new Date().getTime() * 10000) + 621355968000000000).toString(),
        };
        let api = AppConfig.API + ApiHelper.apiQuotation;
        return this.http.post(api, params).pipe(
            map((data: any) => QuotationItem.ToItem(data))
        );
    }


    prices() {
        let api = AppConfig.API + ApiHelper.apiPrices;
        return this.http.get(api).pipe(
            map((data: any) => PriceItem.ToItems(data))
        );
    }
    getIpInfo() {
        let api = "//ipinfo.io/json?token=19fa662cb24914";
        return this.http.get(api).pipe(
            map((data: any) => IpInfoItem.ToItem(data))
        );
    }
    vehicletypes() {
        let api = AppConfig.API + ApiHelper.apiVehicleTypes;
        return this.http.get(api).pipe(
            map((data: any) => VehicleItem.ToItems(data.vehTypes))
        );
    }
    comment(item: Comment) {
        let sourceType = AppConfig.SOURCE_TYPE;
        let phone = item.Phone.indexOf('+') == 0 ? item.Phone : item.DialingCode + item.Phone;
        let params: any = {
            phone: phone,
            name: item.Name,
            email: item.Email,
            source: sourceType,
            subject: item.Subject,
            enqtype: item.EnqType,
            message: item.Content,
            custid: item.CustomerId,
        };

        let api = AppConfig.API + ApiHelper.apiComment;
        return this.http.post(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }

    signIn(item: SignIn) {
        let params: any = {
            email: item.Email,
            passwordhash: item.PasswordHash,
        };

        let api = AppConfig.API + ApiHelper.apiSignIn;
        return this.http.post(api, params).pipe(
            map((data: any) => ProfileItem.ToItem(data))
        );
    }
    signUp(item: SignUp) {
        let params: any = {
            email: item.Email,
            phone: item.Phone,
            surname: item.Surname,
            firstname: item.Firstname,
            dialingcode: item.DialingCode,
            passwordhash: item.PasswordHash,
        };

        let api = AppConfig.API + ApiHelper.apiSignUp;
        return this.http.post(api, params).pipe(
            map((data: any) => ProfileItem.ToItem(data))
        );
    }
    resetPassword(item: string) {
        let sourceType = AppConfig.SOURCE_TYPE;
        let params: any = {
            custid: 0,
            email: item,
            source: sourceType,
        };

        let api = AppConfig.API + ApiHelper.apiResetPassword;
        return this.http.post(api, params).pipe(
            map((data: any) => data)
        );
    }
    saveProfile(item: ProfileItem) {
        let params: any = {
            custid: item.Id,
            phone: item.Phone,
            email: item.Email,
            surname: item.LastName,
            firstname: item.FirstName,
            dialingcode: item.DialingCode,
            smsmailinglist: item.SMSMailingList,
            emailmailinglist: item.EmailMailingList,
        };

        let api = AppConfig.API + ApiHelper.apiUpdateProfile;
        return this.http.put(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }
    savePassword(id: number, code: string, password: string) {
        let params: any = {
            id: id,
            code: code,
            passwordhash: password,
        };

        let api = AppConfig.API + ApiHelper.apiSavePassword;
        return this.http.put(api, params).pipe(
            map((data: any) => data)
        );
    }
    changePassword(id: number, oldPassword: string, password: string) {
        let params: any = {
            custid: id,
            passwordhash: password,
            oldpasswordhash: oldPassword,
        };

        let api = AppConfig.API + ApiHelper.apiChangePassword;
        return this.http.put(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }

    promoCode(item: Booking) {
        let customerId = this.data.Profile && this.data.Profile.Id;
        let params: any = {
            price: item.Quotation.TotalFare,
            promocode: item.Promo.PromoCode,
            currencycode: item.Quotation.CurrencyCode,
            custid: customerId || (item.Contact == null ? 0 : item.Contact.CustomerId) || 0,
        };

        let api = AppConfig.API + ApiHelper.apiPromoCode;
        return this.http.post(api, params).pipe(
            map((data: any) => PromoItem.ToItem(data, item.Promo.PromoCode, item.Quotation.CurrencyCode))
        );
    }
    getPromotion(token: string) {
        let params = new HttpParams().set('token', token);
        let api = AppConfig.API + ApiHelper.apiPromotion;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => PromotionItem.ToItem(data))
        );
    }
    emailReceipt(item: Booking) {
        let sourceType = AppConfig.SOURCE_TYPE;
        let params: any = {
            source: sourceType,
            bookingref: item.Bref
        };

        let api = AppConfig.API + ApiHelper.apiEmailReceipt;
        return this.http.post(api, params).pipe(
            map((data: any) => ProfileItem.ToItem(data))
        );
    }

    electronicPayment(item: Booking, payment: PaymentItem, sandbox: boolean = false) {
        let params: any = {
            jobpart: item.Bref,
            city: payment.City,
            name: payment.Name,
            nonce: payment.Nonce,
            amount: payment.Amount,
            custid: payment.CustomerId,
            sandbox: sandbox.toString(),
            country: payment.CountryCode,
            addressline1: payment.AddressLine1,
            addressline2: payment.AddressLine2,
        };

        let api = AppConfig.API + ApiHelper.apiElectronicPayment;
        return this.http.post(api, params).pipe(
            map((data: any) => ElectronicItem.ToItem(data))
        );
    }
    electronicPaymentBooking(item: Booking, payment: PaymentItem, sandbox: boolean = false) {
        let sourceType = AppConfig.SOURCE_TYPE;

        let viaaddresses = new Array();
        if (item.ViaAddress) {
            item.ViaAddress.forEach(element => {
                if (element != null) {
                    let viaAddress = new ViaAddress();
                    viaAddress.lat = element.Latitude;
                    viaAddress.lng = element.Longitude;
                    viaAddress.postcode = element.Postcode;
                    viaAddress.address = element.Fulladdress;
                    viaaddresses.push(viaAddress);
                }
            });
        }

        let time = item.Time.Time;
        if (time == null) {
            time = (new Date());
            let minute = item.Minute == 0 ? 15 : item.Minute;
            time.setMinutes(time.getMinutes() + minute);
        }
        if (item.Time.PickUpType == PickupType.Now) {
            time = (new Date());
            let minute = item.Quotation != null ? item.Quotation.TravelTime : 0;
            time.setMinutes(time.getMinutes() + minute);
        }
        let flight = item.Flight == null ? new Flight() : item.Flight;
        let returnFlight = item.ReturnFlight == null ? new Flight() : item.ReturnFlight;

        let exparrival = '';
        if (flight.ArrivingFrom != null && flight.ArrivingFrom.Time && flight.ArrivingFrom.Time != null) {
            if (typeof flight.ArrivingFrom.Time == 'string') exparrival = (new Date(flight.ArrivingFrom.Time)).toISOString().slice(0, 19);
            else exparrival = flight.ArrivingFrom.Time.toISOString().slice(0, 19);
        }

        let returnexparrival = '';
        if (returnFlight.ArrivingFrom != null && returnFlight.ArrivingFrom.Time && returnFlight.ArrivingFrom.Time != null) {
            if (typeof returnFlight.ArrivingFrom.Time == 'string') returnexparrival = (new Date(returnFlight.ArrivingFrom.Time)).toISOString().slice(0, 19);
            else returnexparrival = returnFlight.ArrivingFrom.Time.toISOString().slice(0, 19);
        }

        let vehicleId = this.data.Booking.VehicleId || this.data.Booking.Vehicle.Id,
            additionalPrice = this.data.Booking.Quotation.AdditionalPrices &&
                this.data.Booking.Quotation.AdditionalPrices.find(c => c.VehTypeId == vehicleId);

        if (!item.Options)
            item.Options = new BookingOption();
        let pet = item.Options.SmallPets ? parseInt(item.Options.SmallPets) : 0,
            luggage = item.Options.Luggages ? parseInt(item.Options.Luggages) : 0,
            handBag = item.Options.HandBags ? parseInt(item.Options.HandBags) : 0,
            equipment = item.Options.OtherPets ? parseInt(item.Options.OtherPets) : 0,
            largeChild = item.Options.LargeChilds ? parseInt(item.Options.LargeChilds) : 0,
            smallChild = item.Options.SmallChilds ? parseInt(item.Options.SmallChilds) : 0,
            passenger = (item.Options.Passengers ? parseInt(item.Options.Passengers) : 0) + largeChild + smallChild;

        let dialingContactCode = item.ContactDb.Phone
            ? item.ContactDb.DialingCode.indexOf('+') == 0 ? item.ContactDb.DialingCode : '+' + item.ContactDb.DialingCode
            : '';
        let dialingCustomerCode = item.Customer.Phone
            ? item.Customer.DialingCode.indexOf('+') == 0 ? item.Customer.DialingCode : '+' + item.Customer.DialingCode
            : '';
        let rtntype = '';
        if (item.WaitAndReturn) rtntype = 'w&r';
        else if (item.HasReturn && item.ReturnDate != null) rtntype = 'rtn';
        let params: any = {
            note: item.Note,
            rtntype: rtntype,
            source: sourceType,
            exparrival: exparrival,
            flightno: flight.Number,
            vehtype: item.Vehicle.Name,
            vehtypeid: item.Vehicle.Id,
            viaaddresses: viaaddresses,
            fare: additionalPrice.Fare,
            pickupnote: item.PickUpNote,
            noofsmallpets: pet ? pet : 0,
            pick: item.PickUp.Fulladdress,
            gbpfare: additionalPrice.Fare,
            dropoffnote: item.PickDownNote,
            doff: item.PickDown.Fulladdress,
            paymentmethod: item.PaymentType,
            bookerref: item.Customer.OurRef,
            waitingtime: flight.WaitingTime,
            returnexparrival: returnexparrival,
            pickcategory: item.PickUp.Category,
            pickpostcode: item.PickUp.Postcode,
            passengername: item.ContactDb.Name,
            returnflightno: returnFlight.Number,
            totalfare: additionalPrice.TotalFare,
            promocodeid: item.Promo.PromoCodeId,
            noofhandBags: handBag ? handBag : 0,
            noofluggages: luggage ? luggage : 0,
            passengeremail: item.ContactDb.Email,
            passengerphone: item.ContactDb.Phone,
            droppostcode: item.PickDown.Postcode,
            doffcategory: item.PickDown.Category,
            pklat: item.Quotation.PickUp.Latitude,
            passengerphonecode: dialingContactCode,
            pklng: item.Quotation.PickUp.Longitude,
            dolat: item.Quotation.DropOff.Latitude,
            traveltime: item.Quotation.JourneyTime,
            returnfare: additionalPrice.ReturnFare,
            dolng: item.Quotation.DropOff.Longitude,
            gbptotalfare: additionalPrice.TotalFare,
            rtntraveltime: item.Quotation.TravelTime,
            noofequipments: equipment ? equipment : 0,
            noofpassengers: passenger ? passenger : 0,
            gbpreturnfare: additionalPrice.ReturnFare,
            currencycode: additionalPrice.CurrencyCode,
            arrivingfromairportname: flight.AirportName,
            routedistance: item.Quotation.RouteDistance,
            arrivingfromairportcode: flight.AirportCode,
            bookingdate: UtilityHelper.ToLocalTime(time),
            nooflargechildseats: largeChild ? largeChild : 0,
            noofsmallchildseats: smallChild ? smallChild : 0,
            returntraveltime: item.Quotation.ReturnJourneyTime,
            custname: item.Customer.Name || item.ContactDb.Name,
            returndate: UtilityHelper.ToLocalTime(item.ReturnDate),
            custemail: item.Customer.Email || item.ContactDb.Email,
            custphone: item.Customer.Phone || item.ContactDb.Phone,
            returnarrivingfromairportname: returnFlight.AirportName,
            returnroutedistance: item.Quotation.ReturnRouteDistance,
            returnarrivingfromairportcode: returnFlight.AirportCode,
            custid: payment.CustomerId || item.ContactDb.CustomerId,
            flightdetailsunavailable: flight.IsFlightDetail.toString(),
            custdialingcode: dialingCustomerCode || dialingContactCode,
            isasap: (item.Time.PickUpType == PickupType.Now).toString(),
            custsurname: item.Customer.LastName || item.ContactDb.LastName,
            custfirstname: item.Customer.FirstName || item.ContactDb.FirstName,
            returnflightdetailsunavailable: returnFlight.IsFlightDetail.toString(),
            bookerid: item.ContactDb.BookerId == null || item.ContactDb.BookerId == 0 ? null : item.ContactDb.BookerId,
        };
        if (payment != null) {
            params.paymentdetails = {
                city: payment.City,
                name: payment.Name,
                nonce: payment.Nonce,
                amount: payment.Amount,
                custid: payment.CustomerId,
                sandbox: sandbox.toString(),
                country: payment.CountryCode,
                addressline1: payment.AddressLine1,
                addressline2: payment.AddressLine2,
            };
        } else {
            params.paymentdetails = {};
        }
        if (item.ContactDb.AsAccount) {
            params['passwordhash'] = item.ContactDb.PasswordHash;
        }
        let api = AppConfig.API + ApiHelper.apiElectronicPaymentBooking;
        return this.http.post(api, params).pipe(
            map((data: any) => ElectronicBookingItem.ToItem(data))
        );
    }
    electronicPaymentBookingStripe(item: Booking, payment: PaymentItem, sandbox: boolean = false) {
        if (payment && payment.Retry) {
            let api = AppConfig.API + ApiHelper.apiPaymentIntent + '?jobpartid=' + item.Bref;
            return this.http.get(api).pipe(
                map((data: any) => ElectronicBookingStripeItem.ToItemFromBooking(data))
            );
        } else {
            let params = this.createBookingParams(item);
            let api = AppConfig.API + ApiHelper.apiElectronicPaymentBookingTest;
            return this.http.post(api, params).pipe(
                map((data: any) => ElectronicBookingStripeItem.ToItem(data))
            );
        }
    }

    bookingInfo(code: string) {
        let params: any = {
            code: code,
        };
        let api = AppConfig.API + ApiHelper.apiBookingInfo;
        return this.http.post(api, params).pipe(
            map((data: any) => BookingInfoItem.ToItem(data))
        );
    }
    currentBookings(id: number, bookerId: number) {
        let params = new HttpParams().set('custid', id.toString());
        if (bookerId != null && bookerId != 0)
            params = params.set('bookerid', bookerId.toString());

        let api = AppConfig.API + ApiHelper.apiCurrentBookings;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => Booking.ToItems(data.jlist, id))
        );
    }
    journeyHistory(id: number, bookerId: number, filter: number = 0) {
        let params = new HttpParams()
            .set('custid', id.toString())
            .set('filter', filter.toString());
        if (bookerId != null && bookerId != 0)
            params = params.set('bookerid', bookerId.toString());

        let api = AppConfig.API + ApiHelper.apiJourneyHistory;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => Booking.ToItems(data.jlist, id))
        );
    }

    getBookingDetail(id: string) {
        let params = new HttpParams().set('jobid', id);
        let api = AppConfig.API + ApiHelper.apiBookingDetails;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => Booking.ToItem(data.bookingdetails, 0))
        );
    }
    getStripePaymentDetail(id: number, code: string, paypal: boolean = false) {
        let params = new HttpParams().set('code', code).set('id', id.toString());
        if (paypal)
            params = params.set('paymentmethod', 'paypal');

        let api = AppConfig.API + ApiHelper.apiStripePaymentIntent;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => Booking.ToItemFromBookingPayment(data))
        );
    }

    trackVehicle(id: string) {
        let params = new HttpParams().set('jobpartid', id);
        let api = AppConfig.API + ApiHelper.apiTrackVehicle;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => TrackVehicleItem.ToItem(data))
        );
    }
    trackVehicleByCode(id: string, code: string) {
        let params = new HttpParams()
            .set('id', id.toString())
            .set('jobpartid', '0')
            .set('code', code);
        let api = AppConfig.API + ApiHelper.apiTrackVehicle;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => TrackVehicleItem.ToItem(data))
        );
    }

    driverPhoto(id: string) {
        let params = new HttpParams().set('jobpartid', id);

        let api = AppConfig.API + ApiHelper.apiDriverPhoto;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => DriverPhotoItem.ToItem(data))
        );
    }
    driverFootprint(id: string) {
        let params = new HttpParams().set('jobpartid', id);
        let api = AppConfig.API + ApiHelper.apiDriverFootprint;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => DriverFootprintItem.ToItem(data))
        );
    }

    cancelBooking(item: Booking) {
        let sourceType = AppConfig.SOURCE_TYPE;
        let params: any = {
            source: sourceType,
            bookingref: parseInt(item.Bref),
        };

        let api = AppConfig.API + ApiHelper.apiCancelBooking;
        return this.http.post(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }
    updateBooking(item: UpdateBooking) {
        let params: any = item.ReturnJobpartId && item.ReturnJobpartId != item.JobpartId
            ? {
                transactionid: item.Id,
                jobpartid: item.JobpartId,
                transactionstatus: item.Status,
                returnjobpartid: item.ReturnJobpartId,
            }
            : {
                transactionid: item.Id,
                jobpartid: item.JobpartId,
                transactionstatus: item.Status,
            };
        let api = AppConfig.API + ApiHelper.apiUpdateBooking;
        return this.http.put(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data)),
        );
    }
    bookerstatement(item: CommissionFilter) {
        if (item.ToDate == null) item.ToDate = new Date();
        if (item.FromDate == null) item.FromDate = new Date();

        let params = new HttpParams()
            .set('bookerid', item.BookerId.toString())
            .set('dateto', UtilityHelper.ToLocalTime(item.ToDate))
            .set('datefrom', UtilityHelper.ToLocalTime(item.FromDate));

        let api = AppConfig.API + ApiHelper.apiBookerStatement;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => CommissionItem.ToItems(data.bookings))
        );
    }

    deleteBookerInvoice(id: number) {
        let api = AppConfig.API + ApiHelper.apiBookerInvoice + '?id=' + id;
        return this.http.delete(api).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }
    getBookerInvoiceReport(id: number) {
        let params = new HttpParams().set('id', id.toString());
        let api = AppConfig.API + ApiHelper.apiBookerInvoiceReport;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => InvoiceReportItem.ToItem(data))
        );
    }
    getBookerInvoices(bookerId: number) {
        let params = new HttpParams().set('bookerid', bookerId.toString());
        let api = AppConfig.API + ApiHelper.apiBookerInvoice;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => InvoiceItem.ToItems(data.invoices))
        );
    }
    addBookerInvoice(item: InvoiceItem) {
        let params: any = {
            id: item.Id,
            dateto: item.DateTo,
            datefrom: item.DateFrom,
            bookerid: item.BookerId,
        };

        let api = AppConfig.API + ApiHelper.apiBookerInvoice;
        return this.http.post(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }
    saveBookerInvoice(item: InvoiceItem) {
        let params: any = {
            id: item.Id,
            dateto: item.DateTo,
            datefrom: item.DateFrom,
            bookerid: item.BookerId,
        };

        let api = AppConfig.API + ApiHelper.apiBookerInvoice;
        return this.http.put(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }

    unzipBookingParams(id: number) {
        let params = new HttpParams().set('id', id.toString());
        let api = AppConfig.APILICENCE + ApiHelper.apiBookingParams;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => Booking.ToItemFromBookingParam(data._params))
        );
    }
    getUrlBooking(trackingCode: string) {
        let params = new HttpParams().set('trackingcode', trackingCode);
        let api = AppConfig.APILICENCE + ApiHelper.apiCustAppBaseUrl;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => BaseUrlItem.ToItem(data))
        );
    }
    getBookingChart(bookerId: number, date: string) {
        let params = new HttpParams()
            .set('weekcommencing', date)
            .set('bookerid', bookerId.toString());
        let api = AppConfig.API + ApiHelper.apiBookingChart;
        return this.http.get(api, { params: params }).pipe(
            map((data: any) => data)
        );
    }
    checkDomain(domain: string, trackingCode: string) {
        let params: any = {
            parentdomain: domain,
            trackingcode: trackingCode,
        };
        let api = AppConfig.APILICENCE + ApiHelper.apiDomainValidate;
        return this.http.post(api, params).pipe(
            map((data: any) => BaseItem.ToItem(data))
        );
    }
    zipBookingParams(item: Booking, trackingCode: string) {
        let time = item.Time.Time;
        if (time == null) {
            time = (new Date());
            let minute = item.Minute == 0 ? 15 : item.Minute;
            time.setMinutes(time.getMinutes() + minute);
        }
        if (item.Time.PickUpType == PickupType.Now) {
            time = (new Date());
            let minute = item.Quotation != null ? item.Quotation.TravelTime : 0;
            time.setMinutes(time.getMinutes() + minute);
        }
        let params: any = {
            trackingcode: trackingCode,
            pickup: item.PickUp == null ? null : {
                category: item.PickUp.Category,
                postcode: item.PickUp.Postcode,
                latitude: item.PickUp.Latitude,
                longitude: item.PickUp.Longitude,
                fulladdress: item.PickUp.Fulladdress
            },
            dropoff: item.PickDown == null ? null : {
                category: item.PickDown.Category,
                postcode: item.PickDown.Postcode,
                latitude: item.PickDown.Latitude,
                longitude: item.PickDown.Longitude,
                fulladdress: item.PickDown.Fulladdress
            },
            datetime: time.toISOString().slice(0, 19),
            vehtypeid: item.Vehicle == null ? 0 : item.Vehicle.Id,
            vehicleid: item.Vehicle == null ? 0 : item.Vehicle.Id,
            isASAP: (item.Time.PickUpType == PickupType.Now).toString(),
        };

        let api = AppConfig.APILICENCE + ApiHelper.apiBookingParams;
        return this.http.post(api, params).pipe(
            map((data: any) => BaseUrlItem.ToItem(data))
        );
    }

    public createBookingParams(item: Booking) {
        let sourceType = AppConfig.SOURCE_TYPE;
        let viaaddresses = new Array();
        if (item.ViaAddress) {
            item.ViaAddress.forEach(element => {
                if (element != null) {
                    let viaAddress = new ViaAddress();
                    viaAddress.lat = element.Latitude;
                    viaAddress.lng = element.Longitude;
                    viaAddress.postcode = element.Postcode;
                    viaAddress.address = element.Fulladdress;
                    viaaddresses.push(viaAddress);
                }
            });
        }

        let time = item.Time.Time;
        if (time == null) {
            time = (new Date());
            let minute = item.Minute == 0 ? 15 : item.Minute;
            time.setMinutes(time.getMinutes() + minute);
        }
        if (item.Time.PickUpType == PickupType.Now) {
            time = (new Date());
            let minute = item.Quotation != null ? item.Quotation.TravelTime : 0;
            time.setMinutes(time.getMinutes() + minute);
        }
        let flight = item.Flight == null ? new Flight() : item.Flight;
        let returnFlight = item.ReturnFlight == null ? new Flight() : item.ReturnFlight;

        let exparrival = '';
        if (flight.ArrivingFrom != null && flight.ArrivingFrom.Time && flight.ArrivingFrom.Time != null) {
            if (typeof flight.ArrivingFrom.Time == 'string') exparrival = (new Date(flight.ArrivingFrom.Time)).toISOString().slice(0, 19);
            else exparrival = flight.ArrivingFrom.Time.toISOString().slice(0, 19);
        }

        let returnexparrival = '';
        if (returnFlight.ArrivingFrom != null && returnFlight.ArrivingFrom.Time && returnFlight.ArrivingFrom.Time != null) {
            if (typeof returnFlight.ArrivingFrom.Time == 'string') returnexparrival = (new Date(returnFlight.ArrivingFrom.Time)).toISOString().slice(0, 19);
            else returnexparrival = returnFlight.ArrivingFrom.Time.toISOString().slice(0, 19);
        }

        let vehicleId = this.data.Booking.VehicleId || this.data.Booking.Vehicle.Id,
            additionalPrice = this.data.Booking.Quotation.AdditionalPrices &&
                this.data.Booking.Quotation.AdditionalPrices.find(c => c.VehTypeId == vehicleId);


        if (!item.Options)
            item.Options = new BookingOption();
        let pet = item.Options.SmallPets ? parseInt(item.Options.SmallPets) : 0,
            luggage = item.Options.Luggages ? parseInt(item.Options.Luggages) : 0,
            handBag = item.Options.HandBags ? parseInt(item.Options.HandBags) : 0,
            equipment = item.Options.OtherPets ? parseInt(item.Options.OtherPets) : 0,
            largeChild = item.Options.LargeChilds ? parseInt(item.Options.LargeChilds) : 0,
            smallChild = item.Options.SmallChilds ? parseInt(item.Options.SmallChilds) : 0,
            passenger = (item.Options.Passengers ? parseInt(item.Options.Passengers) : 0) + largeChild + smallChild;

        item.Contact.Name = item.Contact.Name || (item.Contact.FirstName + ' ' + item.Contact.LastName);
        let dialingContactCode = item.Contact.DialingCode
            ? item.Contact.DialingCode.indexOf('+') == 0 ? item.Contact.DialingCode : '+' + item.Contact.DialingCode
            : '';
        let dialingCustomerCode = item.Customer.DialingCode
            ? item.Customer.DialingCode.indexOf('+') == 0 ? item.Customer.DialingCode : '+' + item.Customer.DialingCode
            : '';
        let dialingContactDbCode = item.ContactDb.DialingCode
            ? item.ContactDb.DialingCode.indexOf('+') == 0 ? item.ContactDb.DialingCode : '+' + item.ContactDb.DialingCode
            : '';
        let rtntype = '';
        if (item.WaitAndReturn) rtntype = 'w&r';
        else if (item.HasReturn && item.ReturnDate != null) rtntype = 'rtn';
        let params: any = {
            note: item.Note,
            rtntype: rtntype,
            source: sourceType,
            exparrival: exparrival,
            flightno: flight.Number,
            vehtype: item.Vehicle.Name,
            vehtypeid: item.Vehicle.Id,
            viaaddresses: viaaddresses,
            fare: additionalPrice.Fare,
            pickupnote: item.PickUpNote,
            noofsmallpets: pet ? pet : 0,
            gbpfare: additionalPrice.Fare,
            pick: item.PickUp.Fulladdress,
            dropoffnote: item.PickDownNote,
            doff: item.PickDown.Fulladdress,
            paymentmethod: item.PaymentType,
            bookerref: item.Customer.OurRef,
            waitingtime: flight.WaitingTime,
            returnexparrival: returnexparrival,
            pickcategory: item.PickUp.Category,
            pickpostcode: item.PickUp.Postcode,
            returnflightno: returnFlight.Number,
            totalfare: additionalPrice.TotalFare,
            promocodeid: item.Promo.PromoCodeId,
            noofhandBags: handBag ? handBag : 0,
            noofluggages: luggage ? luggage : 0,
            droppostcode: item.PickDown.Postcode,
            doffcategory: item.PickDown.Category,
            pklat: item.Quotation.PickUp.Latitude,
            returnfare: additionalPrice.ReturnFare,
            pklng: item.Quotation.PickUp.Longitude,
            dolat: item.Quotation.DropOff.Latitude,
            traveltime: item.Quotation.JourneyTime,
            dolng: item.Quotation.DropOff.Longitude,
            gbptotalfare: additionalPrice.TotalFare,
            rtntraveltime: item.Quotation.TravelTime,
            noofequipments: equipment ? equipment : 0,
            noofpassengers: passenger ? passenger : 0,
            gbpreturnfare: additionalPrice.ReturnFare,
            currencycode: additionalPrice.CurrencyCode,
            routedistance: item.Quotation.RouteDistance,
            arrivingfromairportcode: flight.AirportCode,
            bookingdate: UtilityHelper.ToLocalTime(time),
            nooflargechildseats: largeChild ? largeChild : 0,
            noofsmallchildseats: smallChild ? smallChild : 0,
            jobPartId: item.Bref ? parseInt(item.Bref) : null,
            returntraveltime: item.Quotation.ReturnJourneyTime,
            returndate: UtilityHelper.ToLocalTime(item.ReturnDate),
            returnroutedistance: item.Quotation.ReturnRouteDistance,
            returnarrivingfromairportcode: returnFlight.AirportCode,
            flightdetailsunavailable: flight.IsFlightDetail.toString(),
            isasap: (item.Time.PickUpType == PickupType.Now).toString(),
            arrivingfromairportname: flight.AirportName || flight.AirportText,
            returnJobpartId: item.ReturnRef ? parseInt(item.ReturnRef) : null,
            returnflightdetailsunavailable: returnFlight.IsFlightDetail.toString(),
            returnarrivingfromairportname: returnFlight.AirportName || returnFlight.AirportText,
            bookerid: item.ContactDb.BookerId == null || item.ContactDb.BookerId == 0 ? null : item.ContactDb.BookerId,
        };
        if (item.Contact.PasswordHash) {
            params['passwordhash'] = item.Contact.PasswordHash;
        }
        if (!item.ContactDb.BookerId) {
            params['custemail'] = item.ContactDb.Email;
            params['custphone'] = item.ContactDb.Phone;
            params['custid'] = item.ContactDb.CustomerId;
            params['custsurname'] = item.ContactDb.LastName;
            params['custdialingcode'] = dialingContactDbCode;
            params['custfirstname'] = item.ContactDb.FirstName;
            if (environment.allowGuest) {
                if (!params['custemail']) params['custemail'] = item.Contact.Email;
                if (!params['custphone']) params['custphone'] = item.Contact.Phone;
                if (!params['custsurname']) params['custsurname'] = item.Contact.LastName;
                if (!params['custfirstname']) params['custfirstname'] = item.Contact.FirstName;
                if (!params['custdialingcode']) params['custdialingcode'] = dialingContactCode;
            }

            params['passengername'] = item.Contact.Name || item.ContactDb.Name;
            params['passengeremail'] = item.Contact.Email || item.ContactDb.Email;
            params['passengerphone'] = item.Contact.Phone || item.ContactDb.Phone;
            params['passengerphonecode'] = dialingContactCode || dialingContactDbCode;
        } else {
            if (item.Customer) {
                params['custref'] = item.Customer.OurRef;
                params['custemail'] = item.Customer.Email;
                params['custphone'] = item.Customer.Phone;
                params['custid'] = item.Customer.CustomerId;
                params['custsurname'] = item.Customer.LastName;
                params['custfirstname'] = item.Customer.FirstName;
                params['custdialingcode'] = item.Customer.DialingCode;
            }
            params['passengername'] = item.Contact.Name || item.ContactDb.Name;
            params['passengeremail'] = item.Contact.Email || item.ContactDb.Email;
            params['passengerphone'] = item.Contact.Phone || item.ContactDb.Phone;
            params['passengerphonecode'] = dialingContactCode || dialingContactDbCode;
        }
        if (!params['custid']) {
            if (!params['custemail']) params['custemail'] = params['passengeremail'];
            if (!params['custphone']) params['custphone'] = params['passengerphone'];
            if (!params['custfirstname']) params['custfirstname'] = params['passengername'];
            if (!params['custdialingcode']) params['custdialingcode'] = params['passengerphonecode'];
        }
        return params;
    }
}